<template>
  <nav :aria-label="$t.utilityNavigationLabel" class="text-xs lh-2">
    <client-only>
      <vf-link
        v-if="$feature.showSignInLinkMobileOnTop && !auth.loggedIn"
        class="lg:hidden"
        role="button"
        to="/sign-in"
        variant="quiet"
        @click.prevent.capture="handleSignIn"
      >
        {{ $t.signIn }}
      </vf-link>
    </client-only>
    <vf-location-selector @click="handleClick($t.locationSelector)" />
    <client-only>
      <template v-if="enableLoyaltyUtilityNav">
        <vf-link
          v-if="auth.loggedIn && user.isLoyaltyMember"
          to="/account/loyalty"
          variant="quiet"
          @click="handleClick($t.loyaltyUtilityNavLabel)"
        >
          {{ $t.loyaltyUtilityNavLabel }}
        </vf-link>
        <vf-link
          v-else
          to="/loyalty"
          variant="quiet"
          @click="handleClick($t.earnRewards)"
        >
          {{ $t.earnRewards }}
        </vf-link>
      </template>
    </client-only>
    <template v-for="({ type, to, title }, key) in links" :key>
      <template v-if="type === 'store-locator'">
        <vf-link :to variant="quiet" @click="handleClick(title)">
          {{ title }}
        </vf-link>
        <vf-link
          v-if="giftCardLocales.includes($locale)"
          to="/gift-card-page"
          variant="quiet"
          @click="handleClick($t.giftCardUtilityNavigation)"
        >
          {{ $t.giftCardUtilityNavigation }}
        </vf-link>
      </template>
      <vf-link v-else-if="type !== 'chat'" :to variant="quiet" @click="handleClick(title)">
        {{ title }}
      </vf-link>
    </template>
    <vf-link
      v-if="$feature.allowFavorites"
      class="flex center gap-1"
      to="/favorites"
      variant="quiet"
      @click="handleClick($t.favorites)"
    >
      {{ $t.favorites }}
      <span
        v-if="favoritesStore.favorites?.length"
        class="pointer-events-none h-4 w-4 flex select-none center b-2 b-white rounded-full bg-brand-2 c-white"
        style="font-size: 0.5rem"
      >
        {{ favoritesStore.favoritesCount }}
      </span>
    </vf-link>
    <client-only>
      <template v-if="auth.loggedIn">
        <vf-link to="/account" variant="quiet" @click="handleClick($t.myAccount)">
          {{ $t.myAccount }}
        </vf-link>
        <base-button class="hover:underlined" @click="handleSignOut">
          {{ $t.signOut }}
        </base-button>
      </template>
      <template v-else>
        <vf-link
          :class="{ '<lg:hidden': $feature.showSignInLinkMobileOnTop }"
          role="button"
          to="/sign-in"
          variant="quiet"
          @click.prevent.capture="handleSignIn"
        >
          {{ $t.signIn }}
        </vf-link>
      </template>
    </client-only>
    <client-only>
      <base-button
        v-if="chatLink && $feature.configLivechat.enabled"
        class="hover:underlined"
        data-chat="help:widget"
        disabled
        style="display: none"
        @click="openChat"
      >
        {{ chatLink.title }}
      </base-button>
    </client-only>
  </nav>
</template>

<script lang="ts" setup>
import type { NavigationLink } from '#types/navigation'

const { links } = defineProps<{
  links: NavigationLink[]
}>()

const emit = defineEmits<{
  /**
   * Emits when a navigation item is clicked
   */
  click: []
}>()

const auth = useAuthStore()
const { DialogSignIn } = useDialogsStore()
const favoritesStore = useFavoritesStore()
const user = useUserStore()
const { $gtm, $t } = useNuxtApp()
const { enableLoyaltyUtilityNav } = useFeatureFlags()
const { giftCardLocales } = useAppConfig().components.vf.utilityNavigation
const chatLink = links.find(({ type }) => type === 'chat')

const handleClick = (title: string) => {
  emit('click')
  $gtm.push('topNav.onClickUtilityLink', fullCapitalize(title))
}

const handleSignIn = async () => {
  handleClick($t.signIn)
  // Workaround until the bug in radix-vue is fixed
  // https://github.com/radix-vue/radix-vue/issues/539
  await nextTick()
  DialogSignIn.open({
    formLocation: 'modal:single:header:none'
  })
}

const handleSignOut = () => {
  handleClick($t.signOut)
  auth.logout()
}

const openChat = () => {
  window.dispatchEvent(new CustomEvent('onHelpButtonClick'))
}
</script>
